@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");

body {
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  scrollbar-width: thin;
  scrollbar-color: #999999 transparent;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  opacity: 0.3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #999999;
  opacity: 0.3;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  background-color: #efebe9;
}